import React from 'react';
import { ReactTyped } from "react-typed";
import Nav from '../Nav';

const Home = () => {
  return (
    <div>
      <Nav />
      <div className="flex flex-col justify-center items-center h-screen min-h-screen mx-auto bg-green1/50">
        <div>
          <p className='sm:text-2xl md:text-2xl lg:text-2xl text-1xl font-display text-center align-middle pb-3'>
            Math Sprouts Curriculum
          </p>

          <h1 className="sm:text-5xl sm:max-w-[500px] md:text-8xl md:max-w-[700px] lg:text-8xl lg:max-w-[800px] text-5xl max-w-[400px] font-display text-center align-middle">
            Teaching math <span className="text-blue2"> made easy </span> at your daycare.
          </h1>

          <div className='flex justify-center items-baseline mt-12'>
            <button
              type="button"
              className="text-xs md:text-xl lg:text-xl ml-2 rounded-full bg-green2 px-4 py-2 font-display text-blue shadow-sm hover:bg-blue2 hover:text-green1"
            >
              Numeracy
            </button>

            <button
              type="button"
              className="text-xs md:text-xl lg:text-xl ml-2 rounded-full bg-green2 px-4 py-2 font-display text-blue shadow-sm hover:bg-blue2 hover:text-green1"
            >
              Algebra
            </button>

            <button
              type="button"
              className="text-xs md:text-xl lg:text-xl ml-2 rounded-full bg-green2 px-4 py-2 font-display text-blue shadow-sm hover:bg-blue2 hover:text-green1"
            >
              Geometry
            </button>

            <button
              type="button"
              className="text-xs md:text-xl lg:text-xl ml-2 rounded-full bg-green2 px-4 py-2 font-display text-blue shadow-sm hover:bg-blue2 hover:text-green1"
            >
              Size
            </button>

            <button
              type="button"
              className="text-xs md:text-xl lg:text-xl ml-2 rounded-full bg-green2 px-4 py-2 font-display text-blue shadow-sm hover:bg-blue2 hover:text-green1"
            >
              Logic
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
