import React from 'react'
import Home from './components/pages/Home';
import Nav from './components/Nav';
import Pricing from './components/Pricing';
import Numeracy from './components/pages/Numeracy';
import Nav2 from './components/Nav2';

function App() {
  return (
    
    <div>
      <Home />

    </div>
  );
}

export default App;
