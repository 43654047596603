import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";

function Nav2() {
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav);
    };

    return (
      <div className="flex justify-between items-center h-20 max-w[1240px] mx-auto px-4 text-blue1 bg-green1">
      <h1 className="w-full text-3xl font-bold">MS</h1>
      <div className="flex justify-between items-center">
                <ul className="md:flex hidden font-display text-2xl">
                    <li className="p-8">
                        <Link to="/Home">Home</Link>
                    </li>
                    <li className="p-8">
                        <Link to="/Numeracy">Numeracy</Link>
                    </li>
                    <li className="p-8">
                        <Link to="/Geometry">Geometry</Link>
                    </li>
                    <li className="p-8">
                        <Link to="/Algebra">Algebra</Link>
                    </li>
                    <li className="p-8">
                        <Link to="/Logic">Logic</Link>
                    </li>
                    <li className="p-8">
                        <Link to="/Size">Size</Link>
                    </li>
                </ul>
                <div className="md:hidden" onClick={handleNav}>
                    {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
                </div>
                <div
                    className={
                        nav
                            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-green1 ease-in-out duration-500 z-50"
                            : "fixed left-[-100%] top-0 w-[60%] h-full ease-in-out duration-500 z-50"
                    }
                >
                    <h1 className="w-full text-3xl font-bold m-4">MS</h1>
                    <ul className="text-2xl p-4 font-display text-black">
                        <li className="p-4 border-b border-gray-600 hover:text-green1 hover:bg-blue2">
                            <Link to="/Home" onClick={handleNav}>Home</Link>
                        </li>
                        <li className="p-4 border-b border-gray-600 hover:text-green1 hover:bg-blue2">
                            <Link to="/Numeracy" onClick={handleNav}>Numeracy</Link>
                        </li>
                        <li className="p-4 border-b border-gray-600 hover:text-green1 hover:bg-blue2">
                            <Link to="/Geometry" onClick={handleNav}>Geometry</Link>
                        </li>
                        <li className="p-4 border-b border-gray-600 hover:text-green1 hover:bg-blue2">
                            <Link to="/Algebra" onClick={handleNav}>Algebra</Link>
                        </li>
                        <li className="p-4 border-b border-gray-600 hover:text-green1 hover:bg-blue2">
                            <Link to="/Logic" onClick={handleNav}>Logic</Link>
                        </li>
                        <li className="p-4 hover:text-green1 hover:bg-blue2">
                            <Link to="/Size" onClick={handleNav}>Size</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Nav2;
